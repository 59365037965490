import {
  CREATE_MASTER_QR_TICKET_ACTION,
  GET_SITE_DETAILS,
} from "../../constants/actions"

const initialState = {
  details: null, // Initial Job site details
  ticket: null, // Ticket which will be created post form submission
}

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SITE_DETAILS:
      return {
        ...state,
        details: action.payload,
      }
    case CREATE_MASTER_QR_TICKET_ACTION:
      return {
        ...state,
        ticket: action.payload,
      }
    default:
      return state
  }
}

export default siteReducer
